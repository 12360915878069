/* eslint-disable react/no-array-index-key */
import React, { useMemo, useState, useCallback, useEffect, useRef, useContext } from 'react';
import {
    Button,
    Typography,
    Box,
    AppBar,
    IconButton,
    Grid,
    Stack,
    Chip,
    OutlinedInput,
    CircularProgress,
    Select,
    InputLabel,
    MenuItem,
    FormControl,
    Checkbox,
    Theme,
    FormHelperText,
    Container
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguageFromLocalStorage } from '../../utils/languageTranslation.util';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import useFormPersist from 'react-hook-form-persist';
import axios from '../../utils/axios';
import {
    BookingReceipt,
    BookingPaymentTypes,
    Product,
    ProductTypes,
    ValidatedCampaignCode,
    BookingPaymentProviders,
    Locker,
    PromoCodeV2Alpha2,
    PromoCodeCheckoutLightweightV1Alpha1,
    Site,
    CreateSessionResponse,
    ICustomField,
    ICustomFieldComponentModel
} from '../product/interfaces';
import convertToBookingFormat, { formatTime, formatTimeToDateObject } from '../../utils/dateHelper';
import ProductHelpers from '../product/ProductHelpers';
import ProductSummary from '../product/ProductSummary';
// import CheckBox from '../elements/CheckBox';
import TermsAndConditionDialog from '../product/TermsAndConditionDialog';
import PromoSummary from './PromoSummary';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import parsePhoneNumber from 'libphonenumber-js';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles } from '@mui/styles';
import './CartStep.scss';
import Footer from '../footer/Footer';
import countries from '../../data/countriesData.json';
import { BookingFormContext } from './BookingFormContext';
import CustomFieldCheckbox from '../customFields/customFieldCheckbox';
import CustomFieldDropdown from '../customFields/customFieldDropdown';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import CustomFieldText from '../customFields/customFieldText';
import CustomFieldRating from '../customFields/customFieldRating';
import CustomFieldImageUpload from '../customFields/customFieldUploadImage';
import { generateCustomFieldControl, generateCustomFieldValidationSchema } from '../customFields/customFIeldControlFactory';

const useStyles = makeStyles((theme: Theme) => ({
    carouselContainer: {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        scrollSnapType: 'x mandatory',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    carouselBox: {
        display: 'flex',
        flexWrap: 'nowrap'
    },
    prevButton: {
        position: 'absolute',
        cursor: 'pointer',
        left: '9px',
        zIndex: 1,
        paddingRight: '10px',
        backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.2))',
        backgroundSize: '100% 100%',
        display: 'flex',
        marginTop: '-10px',
        paddingTop: '18px',
        paddingBottom: '20px',
        '&:hover': {
            background: 'linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.2))'
        }
    },
    nextButton: {
        position: 'absolute',
        cursor: 'pointer',
        right: '6px',
        zIndex: 1,
        paddingLeft: '10px',
        backgroundImage: 'linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.2))',
        backgroundSize: '100% 100%',
        display: 'flex',
        marginTop: '-10px',
        paddingTop: '18px',
        paddingBottom: '20px',
        '&:hover': {
            background: 'linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.2))'
        }
    },
    roundedInput: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px' // Apply rounded corners to OutlinedInput components
        }
    },
    roundedSelect: {
        '& .MuiSelect-select': {
            borderRadius: '8px' // Apply rounded corners to Select components
        }
    }
}));

interface Props {
    selectedProducts: Product[];
    selectedPromoCodes: PromoCodeV2Alpha2[];
    promoCodesCheckout: PromoCodeCheckoutLightweightV1Alpha1[];
    setSelectedPromoCodes: (promocode: PromoCodeV2Alpha2[]) => void;
    setSelectedProducts: (products: Product[]) => void;
    setPromoCodesCheckout: (promocode: PromoCodeCheckoutLightweightV1Alpha1[]) => void;
    goToPrevStep: () => void;
    goToNextStep: () => void;
    dateRange: [Date | null, Date | null];
    pickupTime: string | null;
    returnTime: string | null;
    siteId: string;
    payOnSite: boolean;
    setReceipt: (receipt: BookingReceipt) => void;
    goToFirstStep: () => void;
    bookingType: BookingPaymentProviders;
    initialSite: string;
    setReturnTime: (returnTime: string) => void;
    setPickupTime: (pickupTime: string) => void;
    currencyISO: string;
    QR: boolean;
    locker: Locker | null;
    bookNowFlow: boolean;
    selectedHour: number | undefined;
    setSelectedHour: (hour: number) => void;
    loadingQR: boolean;
    setLoadingQR: (loadingQR: boolean) => void;
    setQRFlow: (QRFlow: boolean) => void;
    QRFlow: boolean;
    setDateRange: (dateRange: [null | Date, null | Date]) => void;
    backToProducts: () => void;
    siteData: Site | null;
    setClientSecret: (clientSecret: string) => void;
    goToReceiptStep: () => void;
    setPublicStripeKey: (publicStripeKey: string) => void;
    setSessionExpiryTime: (sessionExpiryTime: string) => void;
    setOrderId: (orderId: string) => void;
    timeSettingAmPm: boolean;
    timeZone: string;
    initialCheckoutErrorMessage: string;
    setInitialCheckoutErrorMessage: (initialCheckoutErrorMessage: string) => void;
    setOpenLockerCodes: (openLockerCodes: boolean) => void;
}

const CartStep = ({
    selectedProducts,
    selectedPromoCodes,
    setSelectedPromoCodes,
    setSelectedProducts,
    promoCodesCheckout,
    goToPrevStep,
    goToNextStep,
    goToFirstStep,
    dateRange,
    pickupTime,
    returnTime,
    siteId,
    payOnSite,
    setReceipt,
    bookingType,
    initialSite,
    setReturnTime,
    setPickupTime,
    currencyISO,
    QR,
    locker,
    bookNowFlow,
    selectedHour,
    setSelectedHour,
    loadingQR,
    setLoadingQR,
    setQRFlow,
    QRFlow,
    setDateRange,
    backToProducts,
    siteData,
    setClientSecret,
    goToReceiptStep,
    setPublicStripeKey,
    setSessionExpiryTime,
    setOrderId,
    timeSettingAmPm,
    timeZone,
    initialCheckoutErrorMessage,
    setInitialCheckoutErrorMessage,
    setOpenLockerCodes
}: Props) => {
    const [campaignCode, setCampaignCode] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [validatedCampaignCode, setValidatedCampaignCode] = useState<ValidatedCampaignCode | null>(null);
    const [showCampaignCodeError, setShowCampaignCodeError] = useState(false);
    const [acceptPolicy, setAcceptPolicy] = useState(false);
    const [emailMarketing, setEmailMarketing] = useState(false);
    const [loadingPromo, setLoadingPromo] = useState(false);
    const [loadingBook, setLoadingBook] = useState(false);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [cartFields, setCartFields] = useState<any[]>([]);
    const { t } = useTranslation();
    const classes = useStyles();
    const carouselRef = useRef<HTMLDivElement>(null);
    const selectedButtonRef = useRef<HTMLButtonElement | null>(null);
    const [errorMessage, setErrorMessage] = useState('');

    const { setShowTermsAndConditions } = useContext(BookingFormContext);

    const [atStart, setAtStart] = useState(true);
    const [atEnd, setAtEnd] = useState(false);

    const buttonWidth = 70;
    const totalScrollAmount = 2 * buttonWidth;

    const siteCountryCode = useMemo(() => {
        return countries.countriesData.find((country) => country.labelEn === siteData?.country)?.key ?? 'SE';
    }, [siteData?.country]);

    const handleNext = (): void => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: totalScrollAmount, behavior: 'smooth' });
        }
    };

    const handlePrev = (): void => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: -totalScrollAmount, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (selectedButtonRef.current) {
            const carousel = carouselRef.current;
            const selectedButton = selectedButtonRef.current;

            const offsetLeft = selectedButton.offsetLeft;
            const scrollLeft = offsetLeft - carousel!.offsetWidth / 2 + selectedButton.offsetWidth / 2;

            carousel!.scrollLeft = scrollLeft;
        }
    }, []);

    const generateValidationSchema = (cartSettings) => {
        let schemaFields = {
            phoneNumber: yup
                .string()
                .test(t('translation.cartStep.phoneInvalid'), t('translation.cartStep.invalidPhone'), (v) => {
                    if (!v) return false;
                    const phoneNumber = parsePhoneNumber(v);
                    return phoneNumber ? phoneNumber.isPossible() : false;
                })
                .label('Phone Number'),
            firstName: yup.string().required(t('translation.cartStep.firstNameRequired')),
            lastName: yup.string().required(t('translation.cartStep.lastNameRequired')),
            email: yup.string().email(t('translation.cartStep.emailInvalid')).required(t('translation.cartStep.emailRequired'))
        };

        if (cartSettings?.address?.active && cartSettings?.address?.mandatory) {
            schemaFields['address'] = yup.string().required(t('translation.cartStep.addressRequired'));
        }

        if (cartSettings?.zipCode?.active && cartSettings?.zipCode?.mandatory) {
            schemaFields['zipCode'] = yup.string().required(t('translation.cartStep.zipCodeRequired'));
        }

        if (cartSettings?.city?.active && cartSettings?.city?.mandatory) {
            schemaFields['city'] = yup.string().required(t('translation.cartStep.cityRequired'));
        }

        if (cartSettings?.country?.active && cartSettings?.country?.mandatory) {
            schemaFields['country'] = yup.string().required(t('translation.cartStep.countryRequired'));
        }

        return generateCustomFieldValidationSchema(siteData?.cartSettings?.customFields, t, schemaFields);
    };

    const formSchema = generateValidationSchema(siteData?.cartSettings);

    const {
        handleSubmit,
        control,
        register,
        getValues,
        watch,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(formSchema),
        mode: 'all'
    });

    useFormPersist('form', { watch, setValue, storage: window.localStorage });

    const totalValue = useMemo(() => {
        let value = 0;
        selectedProducts.forEach((item) => {
            if (item.type === ProductTypes.INSURANCE) {
                value += ProductHelpers.getInsurancePrice(item, selectedProducts);
            } else {
                value += item.cost * item.quantity;
            }
        });

        let fullPrice = value;
        let discount = 0;

        if (validatedCampaignCode) {
            if (typeof validatedCampaignCode.percentDiscount === "number" && validatedCampaignCode.percentDiscount > 0) {
                discount += (fullPrice * validatedCampaignCode.percentDiscount) / 100;
            }
            discount = Math.min(discount, fullPrice);
        }
        value = fullPrice - discount;
        setTotalDiscount(parseFloat(discount.toFixed(2)));

        return parseFloat(value.toFixed(2));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProducts, validatedCampaignCode, selectedPromoCodes]);


    // const getTaxAmount = (taxRate: string, price: number) => {
    //     let tax = 1.25;
    //     switch (taxRate) {
    //         case '0.5':
    //             tax = 1.5;
    //             break;
    //         case '0.25':
    //             tax = 1.25;
    //             break;
    //         case '0.12':
    //             tax = 1.12;
    //             break;
    //         case '0.06':
    //             tax = 1.06;
    //             break;
    //         case '0':
    //             tax = 1.0;
    //             break;
    //         default:
    //             tax = 1.25;
    //             break;
    //     }

    // const isValidVAT = (vat) =>{
    //     return vat >= 0 && vat <= 1;
    // }

    const isValidVAT = (vat: number): boolean => {
        return vat >= 0 && vat <= 1;
    };

    const getTaxAmount = (taxRate: number, price: number) => {
        if (!isValidVAT(taxRate)) {
            return 0;
        }

        const priceWithoutTax = price / (1 + taxRate);
        return parseFloat((price - priceWithoutTax).toFixed(2));
    };

    const totalVATAmount = useMemo(() => {
        const vatAmount = selectedProducts.map((product) => {
            let productCost = 0;

            if (product.type === ProductTypes.INSURANCE) {
                productCost = ProductHelpers.getInsurancePrice(product, selectedProducts);
            } else {
                productCost = product.cost * product.quantity;
            }

            if (validatedCampaignCode && validatedCampaignCode.percentDiscount > 0) {
                productCost *= (100 - validatedCampaignCode.percentDiscount) / 100;
            }

            let productVAT = getTaxAmount(Number(product.vat), productCost); // Adjust this line

            return productVAT;
        });

        return vatAmount.length > 0 ? vatAmount.reduce((partialSum, a) => partialSum + a, 0).toFixed(2) : '0';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProducts, validatedCampaignCode]);

    const handleCustomCartFieldData = () => {
        let customCartFieldData: { customFieldId; value }[] = [];
        if (siteData?.cartSettings?.customFields) {
            customCartFieldData = siteData.cartSettings.customFields.map((field) => ({
                customFieldId: field.id,
                value: getValues(field.id)
            }));
        }

        return customCartFieldData;
    };

    const onClickBook = useCallback(async () => {
        try {
            // const cInsurances = insurances.map((i) => ({ product: i, count: 1 }));
            setLoadingBook(true);

            let customCartFieldData: { customFieldId; value }[] = handleCustomCartFieldData();

            // const startDate = moment(moment(dateRange[0]).format('YYYY/MM/DD') + pickupTime, 'YYYY-MM-DDLT');
            // const endDate = moment(moment(dateRange[1]).format('YYYY/MM/DD') + returnTime, 'YYYY-MM-DDLT');

            const formatString = timeSettingAmPm ? 'YYYY-MM-DDThh:mm A' : 'YYYY-MM-DDTHH:mm';

            const dateTimeString = moment.tz(dateRange[0], timeZone).format('YYYY-MM-DD') + 'T' + pickupTime;
            const returnDateTimeString = moment.tz(dateRange[1], timeZone).format('YYYY-MM-DD') + 'T' + returnTime;

            const startDate = moment.tz(dateTimeString, formatString, timeZone);
            const endDate = moment.tz(returnDateTimeString, formatString, timeZone);

            const response = await axios.post('/booking/v1/checkout', {
                startDate: convertToBookingFormat(startDate),
                endDate: convertToBookingFormat(endDate),
                firstName: getValues('firstName'),
                lastName: getValues('lastName'),
                mail: getValues('email'),
                phoneNumber: getValues('phoneNumber'),
                country: getValues('country'),
                city: getValues('city'),
                address: getValues('address'),
                zipCode: getValues('zipCode'),
                extraInfo: getValues('extraInfo'),
                languageCode: getCurrentLanguageFromLocalStorage() || 'en',
                customerId: 'customerId',
                siteId,
                totalCost: totalValue,
                promoCode: validatedCampaignCode || null,
                accountId: 'accountId',
                channel: 'channel',
                consentToEmailMarketing: emailMarketing,
                paymentProvider: 1, // MANUAL, 0 for BILLMATE
                paymentType,
                paymentStatus,
                products: selectedProducts,
                totalDiscount,
                totalVAT: totalVATAmount,
                lockerId: QRFlow ? locker?.lockerId : undefined,
                customCartFieldData: customCartFieldData
                // checkIn: QRFlow ? moment(dateRange[0]).format('YYYY-MM-DDTHH:mm:ss') : undefined
            });
            const result = response.data as BookingReceipt;
            setLoadingBook(false);
            setReceipt(result);
            goToReceiptStep();
        } catch (e) {
            setLoadingBook(false);
            console.log(e);
        }
        // reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validatedCampaignCode, selectedProducts, dateRange, pickupTime, emailMarketing, paymentType, paymentStatus]);

    //Google analytics
    const trackCheckoutEvent = (products, totalValue) => {
        if (typeof window.gtag === 'function') {
            const itemsForGTM = products.map((product) => ({
                item_id: product.id,
                item_name: typeof product.name === 'string' ? product.name : product.name.en,
                price: product.cost,
                quantity: product.quantity
            }));
            window.gtag('event', 'begin_checkout', {
                currency: currencyISO.trim(),
                value: totalValue,
                items: itemsForGTM
            });
        }
    };

    const goToPayment = useCallback(async () => {
        try {
            setLoadingBook(true);
            const formatString = timeSettingAmPm ? 'YYYY-MM-DDThh:mm A' : 'YYYY-MM-DDTHH:mm';

            const dateTimeString = moment.tz(dateRange[0], timeZone).format('YYYY-MM-DD') + 'T' + pickupTime;
            const returnDateTimeString = moment.tz(dateRange[1], timeZone).format('YYYY-MM-DD') + 'T' + returnTime;

            const startDate = moment.tz(dateTimeString, formatString, timeZone);
            const endDate = moment.tz(returnDateTimeString, formatString, timeZone);

            // Parse the initial URL
            const url = new URL(initialSite);
            // Use URLSearchParams to add the new parameter
            url.searchParams.append('showConfirm', 'true');
            // The updated URL
            const initialSiteUrl = url.toString();

            //send to google analytics
            if (typeof window.gtag === 'function') {
                trackCheckoutEvent(selectedProducts, totalValue);
            }

            let customCartFieldData: { customFieldId; value }[] = handleCustomCartFieldData();

            //post
            const response = await axios.post('/payment/v1/stripe/createSession', {
                startDate: convertToBookingFormat(startDate),
                endDate: convertToBookingFormat(endDate),
                firstName: getValues('firstName'),
                lastName: getValues('lastName'),
                mail: getValues('email'),
                phoneNumber: getValues('phoneNumber'),
                country: getValues('country'),
                city: getValues('city'),
                address: getValues('address'),
                zipCode: getValues('zipCode'),
                extraInfo: getValues('extraInfo'),
                languageCode: getCurrentLanguageFromLocalStorage() || 'en',
                customerId: 'customerId',
                siteId,
                totalCost: totalValue,
                promoCode: validatedCampaignCode || null,
                accountId: 'accountId',
                channel: 'channel',
                consentToEmailMarketing: emailMarketing,
                paymentProvider: 0, // BILLMATE, 1 FOR MANUAL
                paymentType,
                paymentStatus,
                products: selectedProducts,
                promocodes: promoCodesCheckout,
                totalDiscount,
                totalVAT: totalVATAmount,
                returnurl: initialSiteUrl,
                cancelurl: initialSite, // ToDo: decide how to handle cancel in the future, for now only return customer to previous page.
                lockerId: QRFlow ? locker?.lockerId : undefined,
                customCartFieldData: customCartFieldData
                // checkIn: QRFlow ? moment(dateRange[0]).format('YYYY-MM-DDTHH:mm:ss') : undefined
            });

            setLoadingBook(false);
            const result = response.data as CreateSessionResponse;
            if (result.url) {
                if (window.parent) {
                    window.parent.postMessage({ command: 'gokaya-RedirectToStripe', detail: { url: result.url, expireUrl: result.url } }, '*');
                } else {
                    window.postMessage({ command: 'gokaya-RedirectToStripe', detail: { url: result.url, expireUrl: result.url } }, '*');
                }
            } else {
                setClientSecret(result.clientSecret);
                setPublicStripeKey(result.publicStripeKey);
                setSessionExpiryTime(result.sessionExpiryTime);
                setOrderId(result.orderId);
                setOpenLockerCodes(result.openNow);
                goToNextStep();
            }
        } catch (e) {
            setLoadingBook(false);
            // ToDo show error message
            setErrorMessage(t('translation.cartStep.outOfStock'));
            setInitialCheckoutErrorMessage('');
            console.log(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dateRange,
        pickupTime,
        returnTime,
        getValues,
        siteId,
        totalValue,
        validatedCampaignCode,
        emailMarketing,
        paymentType,
        paymentStatus,
        selectedProducts,
        totalDiscount,
        totalVATAmount
    ]);

    const hasProductsInCart = useCallback(
        () => selectedProducts.filter((product) => product.type !== ProductTypes.INSURANCE).length > 0 || selectedPromoCodes.length > 0,
        [selectedProducts, selectedPromoCodes]
    );

    const removeAllProductsExceptForcingFees = () => {
        let selectedProductsReturn = [...selectedProducts];
        selectedProductsReturn = selectedProductsReturn.filter((product) => product.forcingFee);
        setSelectedProducts(selectedProductsReturn);
    };

    useEffect(() => {
        if (!hasProductsInCart()) {
            removeAllProductsExceptForcingFees();
            goToFirstStep();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProducts, selectedPromoCodes]);

    const pickupText = useMemo(() => {
        let text = 'YY/MM/DD';
        if (dateRange[0]) {
            const dateText = moment.tz(dateRange[0], timeZone).format('YYYY/MM/DD');
            // Only append the time if a valid time has been selected
            if (pickupTime) {
                if (pickupTime.includes('AM') || pickupTime.includes('PM')) {
                    return (text = `${dateText} ${pickupTime}`);
                }
                const formattedPickupTime = formatTime(pickupTime, timeSettingAmPm);
                text = `${dateText} ${formattedPickupTime}`;
                if (pickupTime && !timeSettingAmPm) {
                    text = `${dateText} ${pickupTime}`;
                }
            } else if (pickupTime) {
                text = `${dateText} ${pickupTime}`;
            } else {
                text = dateText;
            }
        }
        return text;
    }, [dateRange, pickupTime, timeSettingAmPm]);

    const returnText = useMemo(() => {
        let text = 'YY/MM/DD';
        if (dateRange[1]) {
            const dateText = moment.tz(dateRange[1], timeZone).format('YYYY/MM/DD');
            // Only append the time if a valid time has been selected
            if (returnTime && timeSettingAmPm) {
                if (returnTime.includes('AM') || returnTime.includes('PM')) {
                    return (text = `${dateText} ${returnTime}`);
                }
                const formattedReturnTime = formatTime(returnTime, timeSettingAmPm);
                text = `${dateText} ${formattedReturnTime}`;
                if (returnTime && !timeSettingAmPm) {
                    text = `${dateText} ${returnTime}`;
                }
            } else if (returnTime) {
                text = `${dateText} ${returnTime}`;
            } else {
                text = dateText;
            }
        }
        return text;
    }, [dateRange, returnTime, timeSettingAmPm]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCampaignCode(event.target.value);
    };

    const validateCampaignCode = useCallback(async () => {
        if (!campaignCode) return;

        try {
            setLoadingPromo(true);

            const response = await axios.get(`stripe/v1/promotionCodeValid/${campaignCode}/${siteId}`);

            setLoadingPromo(false);

            if (response.data) {
                setValidatedCampaignCode(response.data);
                setCampaignCode("");
                setShowCampaignCodeError(false);
            }
        } catch (e) {
            console.log(e);
            setLoadingPromo(false);
            setShowCampaignCodeError(true);
        }
    }, [campaignCode]);

    const onChangeHour = (hour: number, e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setSelectedHour(hour);

        const newPickUpTime = bookNowFlow ? moment.tz(timeZone).format('HH:mm') : pickupTime ?? moment.tz(timeZone).format('HH:mm');
        const pickupDateTimeString = moment.tz(dateRange[0], timeZone).format('YYYY-MM-DD') + 'T' + newPickUpTime;
        const newReturnTimeMoment = moment.tz(pickupDateTimeString, timeZone).add(hour, 'hours');
        setReturnTime(newReturnTimeMoment.format('HH:mm'));

        if (bookNowFlow) {
            setPickupTime(newPickUpTime);
        }

        const day = Math.floor(hour >= 24 ? hour / 24 : 1); // Checks if hours is more than 24 and then adds days accordingly
        const tomorrow = moment.tz(timeZone).add(day, 'day').format('YYYY-MM-DD');
        if (newReturnTimeMoment.isSameOrAfter(moment.tz(tomorrow, timeZone))) {
            setDateRange([formatTimeToDateObject(moment.tz(timeZone)), formatTimeToDateObject(moment.tz(timeZone).add(day, 'day'))]);
        } else if (newReturnTimeMoment.isBefore(moment.tz(tomorrow, timeZone))) {
            setDateRange([formatTimeToDateObject(moment.tz(timeZone)), formatTimeToDateObject(moment.tz(timeZone))]);
        }
    };

    function shouldShowBookLabelOnButton(): boolean {
        return bookingType === BookingPaymentProviders.MANUAL || payOnSite || !!siteData?.account.disableStripePayments;
    }

    const handleScroll = () => {
        if (carouselRef.current) {
            const carousel = carouselRef.current;

            if (carousel.scrollLeft === 0) {
                setAtStart(true);
            } else {
                setAtStart(false);
            }

            if (carousel.scrollLeft + carousel.clientWidth >= carousel.scrollWidth) {
                setAtEnd(true);
            } else {
                setAtEnd(false);
            }
        }
    };

    useEffect(() => {
        setLoadingQR(false);

        const carouselElement = carouselRef.current;
        if (carouselElement) {
            carouselElement.addEventListener('scroll', handleScroll);
            handleScroll();
        }

        return () => {
            if (carouselElement) {
                carouselElement.removeEventListener('scroll', handleScroll);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'page_view', {
                page_title: 'Cart Step Page'
            });
        }
    }, []);

    useEffect(() => {
        const fields = [
            {
                name: 'firstName',
                order: siteData?.cartSettings?.name?.order || 0,
                required: siteData?.cartSettings?.name?.mandatory || true,
                active: siteData?.cartSettings?.name?.active || true
            },
            {
                name: 'lastName',
                order: siteData?.cartSettings?.surname?.order || 0,
                required: siteData?.cartSettings?.surname?.mandatory || true,
                active: siteData?.cartSettings?.surname?.active || true
            },
            {
                name: 'email',
                order: siteData?.cartSettings?.email?.order || 0,
                required: siteData?.cartSettings?.email?.mandatory || true,
                active: siteData?.cartSettings?.email?.active || true,
                pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
            },
            {
                name: 'phoneNumber',
                order: siteData?.cartSettings?.phone?.order || 0,
                required: siteData?.cartSettings?.phone?.mandatory || true,
                active: siteData?.cartSettings?.phone?.active || true,
                customControl: (
                    <FormControl variant="outlined">
                        <Controller
                            name="phoneNumber"
                            control={control}
                            render={({ field, fieldState }) => (
                                <>
                                    <PhoneInput
                                        {...field}
                                        containerStyle={{
                                            boxSizing: 'border-box',
                                            borderRadius: '5px'
                                        }}
                                        containerClass={errors.phoneNumber ? 'input-focused-error' : 'input-focused'}
                                        inputStyle={{
                                            fontSize: 'inherit',
                                            boxSizing: 'content-box',
                                            width: 'calc(100% - 52px)'
                                        }}
                                        defaultErrorMessage={errors.phoneNumber?.message}
                                        specialLabel={t('translation.cartStep.phoneNumber')}
                                        data-cy="user-phone"
                                        country={getValues('phoneNumber') ? undefined : siteCountryCode}
                                        onChange={(_, __, ___, formattedValue) => {
                                            // Underscore placeholders only to access formattedValue that is needed for the validation
                                            field.onChange(formattedValue);
                                        }}
                                    />
                                    {errors.phoneNumber && (
                                        <p
                                            style={{
                                                color: '#d32f2f',
                                                fontSize: '12px',
                                                lineHeight: '20px',
                                                margin: '3px 14px 0 14px'
                                            }}>
                                            {t('translation.cartStep.invalidPhone')}
                                        </p>
                                    )}
                                </>
                            )}
                        />
                    </FormControl>
                )
            },
            {
                name: 'address',
                order: siteData?.cartSettings?.address?.order || 0,
                required: siteData?.cartSettings?.address?.mandatory || true,
                active: siteData?.cartSettings?.address?.active || false
            },
            {
                name: 'zipCode',
                order: siteData?.cartSettings?.zipCode?.order || 0,
                required: siteData?.cartSettings?.zipCode?.mandatory || true,
                active: siteData?.cartSettings?.zipCode?.active || false
            },
            {
                name: 'city',
                order: siteData?.cartSettings?.city?.order || 0,
                required: siteData?.cartSettings?.city?.mandatory || true,
                active: siteData?.cartSettings?.city?.active || false
            },
            {
                name: 'country',
                order: siteData?.cartSettings?.country?.order || 0,
                required: siteData?.cartSettings?.country?.mandatory || true,
                active: siteData?.cartSettings?.country?.active || false,
                customControl: (
                    <FormControl variant="outlined" size="small" fullWidth>
                        <Controller
                            name="country"
                            control={control}
                            defaultValue="" // Explicitly set the default value to an empty string
                            render={({ field }) => (
                                <>
                                    <InputLabel id="country-label">{t('translation.cartStep.country')}</InputLabel>
                                    <Select
                                        {...field}
                                        labelId="country-label"
                                        label={t('translation.cartStep.country')}
                                        error={!!errors.country}
                                        displayEmpty // Allows for a non-selection display
                                        data-cy="user-country">
                                        {countries.countriesData.map((country) => {
                                            const label = getCurrentLanguageFromLocalStorage() === 'sv' ? country.labelSv : country.labelEn;
                                            const value = country.key;
                                            return (
                                                <MenuItem key={label} value={value}>
                                                    {label}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </>
                            )}
                        />
                    </FormControl>
                )
            }
        ];
        const fieldsInOrder = fields.sort((a, b) => a.order - b.order);

        setCartFields([...fieldsInOrder]);
    }, [siteData, control, t, errors.phoneNumber, errors.country]);

    useEffect(() => {
        siteData?.cartSettings?.customFields?.forEach((c) => {
            if (c.type === 'CHECKBOX') {
                setValue(c.id, false);
            } else if (c.type === 'RATING') {
                setValue(c.id, 0); // Initialize rating with 0
            } else if (c.type === 'DROPDOWN' && c.options && c.options.length > 0) {
                setValue(c.id, ''); // Empty string for dropdowns
            } else if (c.type === 'IMAGE_UPLOAD') {
                setValue(c.id, ''); // Empty string for image uploads
            } else {
                setValue(c.id, ''); // Text fields and others
            }
        });
    }, []);

    const handleCustomFieldChange = (field, newValue) => {
        setValue(field.id, newValue, {
            shouldValidate: true, // This triggers validation immediately
            shouldDirty: true     // Marks the field as modified
        });
    };

    const customCartFields: ICustomFieldComponentModel[] = useMemo(() => {
        if (siteData?.cartSettings?.customFields) {
            let customFields = siteData.cartSettings.customFields
                .filter(field => field.isActive) // Only process active fields
                .map<ICustomFieldComponentModel>((field: ICustomField) => {
                    return {
                        id: field.id,
                        name: field.name[getCurrentLanguageFromLocalStorage() || 'en'] ?? field.name['sv'],
                        order: field.order ?? 0,  // Use nullish coalescing
                        required: field.isMandatory,
                        active: field.isActive,
                        customControl: generateCustomFieldControl(field, control, handleCustomFieldChange, errors)
                    };
                });
            const customFieldsInOrder = customFields.sort((a, b) => a.order - b.order);
            return customFieldsInOrder;
        }

        return [];
    }, [siteData?.cartSettings?.customFields, control, errors]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ p: 2, pb: 0, position: 'relative', flexGrow: 1 }}>
                {initialCheckoutErrorMessage && <p style={{ color: 'red', textAlign: 'center' }}>{initialCheckoutErrorMessage}</p>}
                {errorMessage && <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>}
                <Box sx={{ display: 'flex', mb: 2, flexDirection: 'column' }}>
                    {siteData?.qrBookingDisplaySettings?.showDates !== false && (
                        <Box sx={{ display: 'flex' }}>
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignSelf: 'center',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <CalendarTodayIcon fontSize="small" color="action" sx={{ fontSize: '0.8em' }} />
                                <Typography variant="body1" color="#0000008a" sx={{ px: 1, fontSize: '12px' }}>
                                    {pickupText}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignSelf: 'center',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <CalendarTodayIcon fontSize="small" color="action" sx={{ fontSize: '0.8em' }} />
                                <Typography variant="body1" color="#0000008a" sx={{ px: 1, fontSize: '12px' }}>
                                    {returnText}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    {QR && (
                        <div style={{ marginTop: '10px' }}>
                            {siteData?.qrBookingDisplaySettings?.showQrTitle !== false && (
                                <Typography variant="h4" color="inherit" style={{ textAlign: 'center', padding: '10px', marginBottom: 10 }}>
                                    {siteData?.qrBookingDisplaySettings?.customQrTitles
                                        ? siteData.qrBookingDisplaySettings.customQrTitles[getCurrentLanguageFromLocalStorage()]
                                        : t('translation.cartStep.hoursToRent')}
                                </Typography>
                            )}
                            {siteData?.qrBookingDisplaySettings?.showQrBookingOptions !== false && (
                                <div className={classes.carouselContainer} ref={carouselRef}>
                                    <div className={classes.carouselBox}>
                                        {(siteData?.qrTimeSelection || [1, 2, 3, 4, 6, 8]).map((hour) => (
                                            <Button
                                                type="button"
                                                key={hour}
                                                value={hour + 1}
                                                disabled={!locker?.availabilityTimes.includes(hour)}
                                                onClick={(e) => onChangeHour(hour, e)}
                                                ref={selectedHour === hour ? selectedButtonRef : null}
                                                sx={{
                                                    border: '1.5px solid',
                                                    borderRadius: 50,
                                                    marginRight: 0.5
                                                }}
                                                variant={
                                                    selectedHour === hour && locker?.availabilityTimes.includes(hour) ? 'contained' : 'outlined'
                                                }>
                                                {hour}H
                                            </Button>
                                        ))}
                                    </div>
                                    {!atStart && (
                                        <IconButton className={classes.prevButton} onClick={handlePrev}>
                                            <ArrowBackIosIcon sx={{ fontSize: '20px' }} color="primary" />
                                        </IconButton>
                                    )}
                                    {!atEnd && (
                                        <IconButton className={classes.nextButton} onClick={handleNext}>
                                            <ArrowForwardIosIcon sx={{ fontSize: '20px' }} color="primary" />
                                        </IconButton>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                    <ProductSummary
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        currencyISO={currencyISO}
                        setQRFlow={setQRFlow}
                        QRFlow={QRFlow}
                        backToProducts={() => backToProducts()}
                    />
                    <PromoSummary
                        setSelectedPromoCodes={setSelectedPromoCodes}
                        currencyISO={currencyISO}
                        backToProducts={() => backToProducts()}
                        selectedPromoCodes={selectedPromoCodes}
                    />
                    <Box sx={{ mb: 1 }}>
                        {totalDiscount > 0 && (
                            <Box sx={{ display: 'flex' }}>
                                <Typography sx={{ flex: 1, textAlign: 'right', marginRight: '24px' }}>
                                    {t('translation.cartStep.discount')}
                                </Typography>
                                <Typography sx={{ textAlign: 'right', color: '#f20000' }}>
                                    {totalDiscount}
                                    {currencyISO}
                                </Typography>
                            </Box>
                        )}
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ flex: 1, textAlign: 'right', marginRight: '24px' }}>{t('translation.cartStep.vat')}:</Typography>
                            <Typography sx={{ textAlign: 'right' }}>
                                {totalVATAmount}
                                {currencyISO}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ flex: 1, textAlign: 'right', marginRight: '24px' }}>{t('translation.cartStep.total')}:</Typography>
                            <Typography sx={{ textAlign: 'right' }}>
                                {totalValue}
                                {currencyISO}
                            </Typography>
                        </Box>
                    </Box>
                    <Grid item xs={12} md={12}>
                        <Stack spacing={1}>
                            <Stack direction="row" spacing={1}>
                                <FormControl variant="outlined" sx={{ flex: 1 }}>
                                    <OutlinedInput
                                        size="small"
                                        value={campaignCode}
                                        color="success"
                                        placeholder={t('translation.cartStep.campaignCode')}
                                        onChange={handleInputChange}
                                        sx={{ borderColor: '#004E31', borderRadius: 1 }}
                                        disabled={loadingPromo || validatedCampaignCode !== null}
                                    />
                                    {showCampaignCodeError && (
                                        <Typography variant="subtitle2" sx={{ color: '#cc0000' }}>
                                            {t('translation.cartStep.campaignCodeAlert')}
                                        </Typography>
                                    )}
                                </FormControl>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    sx={{
                                        borderRadius: 1,
                                        height: 40,
                                        width: '90px',
                                        color: 'primary',
                                    }}
                                    onClick={validateCampaignCode}
                                    disabled={loadingPromo || validatedCampaignCode !== null}>
                                    {loadingPromo ? (
                                        <CircularProgress color="primary" size="small" sx={{ width: '20px' }} />
                                    ) : (
                                        t('translation.cartStep.validateButton')
                                    )}
                                </Button>
                            </Stack>
                            {validatedCampaignCode && (
                                <Stack direction="row" spacing={2}>
                                    <Chip label={`${validatedCampaignCode.code} - ${validatedCampaignCode.percentDiscount}% ${t('translation.cartStep.discount').toLocaleLowerCase()}`}
                                        sx={{ borderRadius: 0, py: '2px', height: 'auto' }} />
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        onClick={() => setValidatedCampaignCode(null)}>
                                        {t('translation.cartStep.removeCode')}
                                    </Typography>
                                </Stack>
                            )}
                            <Box className='customerPaymentDetailsContainer'>
                                <Typography variant="body1" sx={{ margin: 0, fontWeight: 600 }}>{t('translation.cartStep.customerDetails')}</Typography>
                                <style type="text/css">
                                    {'#country-menu { z-index: 2147483647;} .MuiPhoneNumber-flagButton { max-width: 30px; max-height: 30px;}'}
                                </style>
                                <form>
                                    <Stack spacing={1}>
                                        <Box className="customerDetailsContainer">
                                            {cartFields
                                                .filter((f) => f.active)
                                                .map((field) => {
                                                    if (field.customControl) {
                                                        return <Container className="customerDetails" key={field.name}>{field.customControl}</Container>;
                                                    } else {
                                                        return (
                                                            <FormControl
                                                                variant="outlined"
                                                                key={field.name}
                                                                fullWidth
                                                                margin="dense"
                                                                error={Boolean(errors[field.name])}>
                                                                <OutlinedInput
                                                                    size="small"
                                                                    color="success"
                                                                    placeholder={t(`translation.cartStep.${field.name}`)}
                                                                    sx={{ borderRadius: 1, margin: 0 }}
                                                                    {...register(field.name, { required: field.required })}
                                                                    error={Boolean(errors[field.name])}
                                                                />
                                                                {errors[field.name] && <FormHelperText error>{errors[field.name]?.message}</FormHelperText>}
                                                            </FormControl>
                                                        );
                                                    }
                                                })}
                                        </Box>
                                        {customCartFields.map((field) => {
                                            if (field.customControl) {
                                                return <Container className='container' key={field.id}>{field.customControl}</Container>;
                                            }
                                        })}
                                        {bookingType === BookingPaymentProviders.MANUAL && (
                                            <>
                                                <Typography variant="body1">{t('translation.cartStep.payment')}</Typography>
                                                <FormControl variant="outlined" size="small" sx={{ marginTop: '0 !important;' }}>
                                                    <InputLabel id="payment-type-label">{t('translation.cartStep.paymentType')}</InputLabel>
                                                    <Select
                                                        labelId="payment-type-label"
                                                        id="payment-type-select"
                                                        value={paymentType}
                                                        variant="outlined"
                                                        label={t('translation.cartStep.paymentType')}
                                                        onChange={(event) => {
                                                            setPaymentType(event.target.value);
                                                        }}>
                                                        {Object.keys(BookingPaymentTypes)
                                                            .filter((key: any) => isNaN(Number(BookingPaymentTypes[key])))
                                                            .map((item: any, index) => {
                                                                return (
                                                                    <MenuItem key={item} value={item}>
                                                                        {BookingPaymentTypes[item]}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                </FormControl>
                                                <FormControl variant="outlined" size="small">
                                                    <InputLabel id="payment-status-label">{t('translation.cartStep.paymentStatus')}</InputLabel>
                                                    <Select
                                                        labelId="payment-status-label"
                                                        id="payment-status-select"
                                                        value={paymentStatus}
                                                        variant="outlined"
                                                        label={t('translation.cartStep.paymentStatus')}
                                                        onChange={(event) => {
                                                            setPaymentStatus(event.target.value);
                                                        }}>
                                                        <MenuItem value={0}>Unpaid</MenuItem>
                                                        <MenuItem value={1}>Paid</MenuItem>
                                                        <MenuItem value={3}>Partial paid</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </>
                                        )}
                                        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                                            {/* <CheckBox selected={acceptPolicy} onChange={setAcceptPolicy} /> */}
                                            <Checkbox
                                                checked={acceptPolicy}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAcceptPolicy(event.target.checked)}
                                            />
                                            <Typography variant="subtitle1" sx={{ marginLeft: '0 !important' }}>
                                                {t('translation.cartStep.termsAndConditions1')}
                                                <Typography
                                                    sx={{
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer',
                                                        color: 'primary',
                                                        display: 'inline-block',
                                                    }}
                                                    onClick={() => setShowTermsAndConditions(true)}
                                                    variant="body1">
                                                    {t('translation.cartStep.termsAndConditions2')}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: 'red',
                                                        display: 'inline'
                                                    }}
                                                    onClick={() => setShowTermsAndConditions(true)}
                                                    variant="body1">
                                                    *
                                                </Typography>
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                                            {/* <CheckBox selected={emailMarketing} onChange={setEmailMarketing} /> */}
                                            <Checkbox
                                                checked={emailMarketing}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmailMarketing(event.target.checked)}
                                            />
                                            <Typography variant="subtitle1" sx={{ marginLeft: '0 !important', lineHeight: '24px' }}>
                                                {t('translation.cartStep.marketingEmail')} {siteData?.name}.
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </form>
                            </Box>
                        </Stack>
                    </Grid>
                </Box>
            </Box>

            <AppBar
                position="sticky"
                color="transparent"
                sx={{
                    top: 'auto',
                    bottom: 0,
                    textAlign: 'center',
                    pt: 1,
                    boxShadow: 'none',
                    backgroundColor: '#ffffff',
                    pb: 1,
                    zIndex: 9999
                }}>
                <Box sx={{ display: 'flex' }}>
                    <IconButton
                        color="secondary"
                        onClick={() => {
                            goToPrevStep();
                        }}>
                        <ArrowBackIosNewRoundedIcon />
                    </IconButton>
                    <Button
                        variant="contained"
                        disableElevation
                        sx={{ borderRadius: 20, height: 40, px: 2, py: 1, flex: 1, mr: 2 }}
                        onClick={bookingType === BookingPaymentProviders.MANUAL ? handleSubmit(onClickBook) : handleSubmit(goToPayment)}
                        disabled={
                            loadingBook ||
                            !acceptPolicy ||
                            (bookingType === BookingPaymentProviders.MANUAL ? paymentType === '' || paymentStatus === '' : false)
                        }>
                        {loadingBook ? (
                            <CircularProgress size="small" sx={{ width: '20px' }} />
                        ) : shouldShowBookLabelOnButton() ? (
                            t('translation.cartStep.bookButton')
                        ) : (
                            t('translation.cartStep.payButton')
                        )}
                    </Button>
                </Box>
                <Footer />
            </AppBar>
        </Box>
    );
};

export default CartStep;
